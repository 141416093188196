<template>
  <component :is="'vue-multiselect'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="g-dropdown">
    <!-- Add default slots -->
    <!-- <template v-slot:caret="{ toggle, isOpen }"> </template> -->
    <slot v-for="(_, name) in $slots" :name="name" />
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
/**
 * @version 0.0.2
 */
export default {
  name: 'GDropdown',
  extends: VueMultiselect,
  inheritAttrs: false,
  components: {
    VueMultiselect,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '~vue-multiselect/dist/vue-multiselect.min.css';
::v-deep .multiselect__option--highlight {
  color: #222;
  background: #fff;
}
::v-deep .multiselect__option {
  padding: 16px 14px;
  font-size: 14px;
  font-weight: normal;
}
::v-deep .multiselect__single {
  padding-left: 0;
  font-size: 14px;
}
::v-deep .multiselect__tags {
  height: 50px;
  padding: 12px 40px 0 12px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 0;
  .multiselect__placeholder {
    color: #222;
  }
}
.g-dropdown {
  color: #222;
}
::v-deep .multiselect__select {
  height: 50px;
  padding: 12px 8px;
}
::v-deep .multiselect__input {
  font-size: 14px;
}
::v-deep .multiselect__content-wrapper {
  width: calc(100% + 4px);
  margin-left: -2px;
  border: 2px solid #222;
  border-radius: 0;
}
</style>
