<template>
  <div class="settings">
    <div v-if="isSave && !isBadWord" class="saved-settings">
      <span class="saved-text"> {{ $t('settings.saveSuccess') }} </span>
      <XIcon class="x-icon" @click="hidePopup" />
    </div>
    <h1>{{ $t('settings.title') }}</h1>
    <!-- <div class="user-info">
      <span class="settings__uppercase"> my qr code </span>
      !-- <QRCode class="settings__logo" /> --
      <div ref="qrCode" class="settings__logo"></div>
      <span class="uuid"> {{ uuid }} </span>
    </div> -->
    <label class="form-header">{{ $t('settings.email') }}</label>
    <VWFormField
      class="form-field"
      :class="{ valid: email }"
      :placeholder="$t('settings.emailPlaceholder')"
      type="email"
      disabled
      v-model="email"
    />
    <label class="form-header">{{ $t('settings.language') }}</label>
    <GDropdown
      class="form-field dropdown"
      :options="['English', 'Français']"
      v-model="language"
      select-label=""
      :searchable="false"
      :hide-selected="true"
    >
      <template v-slot:caret>
        <img style="position: absolute; top: 21px; right: 18px" src="@/assets/ford/vector.svg?external" />
      </template>
    </GDropdown>
    <!-- <VWFormField
      class="form-field"
      :class="{ valid: email }"
      placeholder="email@email.com"
      type="email"
      disabled
      v-model="email"
    /> -->
    <!-- <label class="form-header"
      >Username:
      <VWFormField
        class="form-field"
        :class="{ valid: username && !isBadWord }"
        v-model="username"
        placeholder="Username"
        type="text"
        :error="isBadWord || (isError && !username)"
        :errorMessage="message"
    /></label> -->
    <!-- <label class="form-header"
      >Password:
      <VWFormField
        class="form-field"
        v-model="currentPass"
        :class="{ valid: currentPass }"
        placeholder="Current Password"
        type="password"
    /></label>
    <label class="form-header"
      ><VWFormField
        class="form-field"
        :class="{ valid: newPass && !mismatch }"
        v-model="newPass"
        :errorMessage="mismatch"
        placeholder="New Password"
        type="password"
    /></label>
    <label class="form-header"
      ><VWFormField
        class="form-field"
        :class="{ valid: confirmPass && !mismatch }"
        v-model="confirmPass"
        :errorMessage="mismatch"
        placeholder="Confirm Password"
        type="password"
    /></label> -->

    <div class="checkbox">
      <!-- <span class="form-header"> {{ $t('settings.optInLabel') }} </span> -->
      <div>
        <!-- <PassportCheckbox v-model="optChecked" name="opt-in" :label="$t('settings.emailConsent')">
          <div class="legal-copy-area" :class="{ collapsed: !legalCopyExpanded }">
            <LegalCopy :lines="$t('legal.emailConsent.lines')" />
          </div>
          <button class="expand-legal-btn" v-if="!legalCopyExpanded" @click.prevent="legalCopyExpanded = true">
            <img src="@/assets/ford/legal-expand.svg?external" />
          </button>
        </PassportCheckbox> -->
      </div>
    </div>

    <PassportButton :label="$t('settings.saveChanges', locale)" class="settings-button" @click="validateForm" />

    <h2>{{ $t('tutorial.howToPlay') }}</h2>
    <PassportButton to="/tutorial" :label="$t('settings.watchTutorial')" />
  </div>
</template>

<script>
import { ApiService } from '@/services/api.service';
import PassportButton from '@/components/PassportButton.vue';
import VWFormField from '@/components/VWFormField.vue';
import XIcon from '@/assets/icons/x_icon.svg';
import PassportCheckbox from '@/components/PassportCheckbox.vue';
import QRCodeStyling from 'qr-code-styling';
import GDropdown from '@/components/GDropdown.vue';
import LegalCopy from '@/components/LegalCopy.vue';

// const BadWords = require('bad-words');

// const badWords = new BadWords();

export default {
  name: 'Login',
  components: {
    PassportButton,
    VWFormField,
    PassportCheckbox,
    XIcon,
    GDropdown,
    LegalCopy,
  },
  data() {
    return {
      username: this.$store.state.username,
      uuid: this.$store.state.uuid,
      email: this.$store.state.email,
      optChecked: this.$store.state.optIn,
      isBadWord: false,
      message: '',
      isSave: false,
      isError: false,
      mismatch: '',
      currentPass: '',
      newPass: '',
      confirmPass: '',
      jwt: this.$store.state.jwt,
      legalCopyExpanded: false,
      locale: this.$store.state.locale,
    };
  },
  computed: {
    language: {
      get() {
        const value = this.locale;
        if (value === 'en') return 'English';
        if (value === 'fr') return 'Français';
        return '';
      },
      set(value) {
        // this.$store.commit('setLocale', value === 'English' ? 'en' : 'fr');
        this.locale = value === 'English' ? 'en' : 'fr';
      },
    },
  },
  mounted() {
    const qrCode = new QRCodeStyling({
      width: 150,
      height: 150,
      margin: 5,
      type: 'svg',
      data: this.uuid,
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 10,
      },
    });
    qrCode.append(this.$refs.qrCode);
  },
  watch: {
    optChecked() {
      // Ford requires copy to be expanded if box is checked
      if (this.optChecked) this.legalCopyExpanded = true;
    },
  },
  methods: {
    async validateForm() {
      ApiService.setAuth(this.jwt);
      this.isError = true;
      // if (badWords.isProfane(this.username)) {
      //   this.isBadWord = true;
      //   this.message = 'Username cannot contain profanity ';
      // } else {
      //   this.message = '';
      // }
      this.isBadWord = false;

      const userPreferences = {
        username: this.username,
        optIn: this.optChecked,
        locale: this.locale,
      };
      if (this.optChecked !== this.$store.state.optIn || this.locale !== this.$store.state.locale) {
        await ApiService.patch(`/user/${this.uuid}`, userPreferences).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.$store.commit('setOptIn', this.optChecked);
            this.$store.commit('setLocale', this.locale);
            this.isSave = true;
          }
        });
      }
      // if (this.newPass !== this.confirmPass) {
      //   this.mismatch = 'Passwords do not match';
      // } else {
      //   this.mismatch = '';
      // }
      const profile = {
        oldPassword: this.currentPass,
        newPassword: this.newPass,
      };
      if (this.username && this.currentPass && this.newPass && this.confirmPass && !this.isBadWord && !this.mismatch) {
        this.isError = false;
        await ApiService.post('/user/change-password', profile).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.isSave = true;
          }
        });
      }
      setTimeout(() => {
        this.isSave = false;
      }, 3000);
    },
    hidePopup() {
      this.isSave = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
  margin-right: auto;
  margin-left: auto;
  // padding: 30px;
  // margin-top: 32px;

  h2 {
    margin-bottom: 32px;
  }
  &__logo {
    margin: 1em 0;
  }
  &__uppercase {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uuid {
  margin-bottom: 2em;
  font-size: 8px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.settings-button {
  margin-top: 19px;
  margin-bottom: 72px;
  // width: 15.625em;
  // height: 3em;
  // margin-top: 2em;
  // margin-bottom: 2em;
  // font-family: $head-font-stack;
  // font-size: 16px;
  // text-align: center;
  // text-transform: uppercase;
}
.form-header {
  width: 100%;
  margin-bottom: 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #222;
  text-align: left;
  text-transform: uppercase;
  @include bp-sm-phone-landscape {
    margin-top: 1em;
  }
}
.form-field {
  &.dropdown {
    border: 2px solid #222;
    fill: #fff;
  }
  &.valid {
    background-image: url('../assets/icons/checkmark.svg?external');
    background-repeat: no-repeat;
    background-position: right;
    background-position-x: 95%;
    background-size: 15px;
  }

  z-index: 1;
  width: 257px;
  margin-bottom: 33px;
  @include bp-sm-phone-landscape {
    width: 380px;
  }
}
.logo {
  display: block;
  width: 2.5em;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}
.checkbox {
  .communications {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
  .label-holder {
    margin-top: 1em;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1em;
}
::v-deep .g-checkbox .label.after {
  margin-left: 1em;
  font-size: 14px;
  line-height: 16px;
  color: #222;
  // font-weight: normal;
  text-align: left;
  letter-spacing: -0.27px;
}
::v-deep .g-checkbox {
  display: flex;
  align-items: flex-start;
}
::v-deep .passport-checkbox .checkbox {
  margin-top: 2px;
  border-color: #222;
  @include bp-sm-phone-landscape {
    margin-top: 0;
  }
}
.saved-settings {
  position: absolute;
  top: 45px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background-color: $primary-color;
}
.saved-text {
  margin-left: 1em;
  font-size: 16px;
  color: #fff;
}
.x-icon {
  display: flex;
  width: 20px;
  margin-right: 1em;
}

.legal-copy-area {
  // cursor: default;
  &.collapsed {
    height: 100px;
    overflow: hidden;
    mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
  }
}
.expand-legal-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 44px 120px 8px 120px;
  cursor: pointer;
  background: none;
  border: none;
  transform: translateX(-50%);
}
</style>
